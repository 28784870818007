<template>
  <div class="steps_box">
    <div class="page_info">
      <el-row>
        <el-col :span="8" v-for="(item,index) in step_arr" :key="index">
          <div class="box_list" :class="item.active ?'untreated':''">
            <div class="box_li dib">
              <div class="num">
                <span class="iconfont" :class="item.icon2"></span>
                <span class="text dib">{{item.title}}</span>
              </div>
              <div class="icon">
                <span class="iconfont" :class="item.icon"></span>
              </div>
              <div class="hint" v-html="item.info" ></div>
            </div>
            <span class="icon_right iconfont iconjiantou" v-if="step_arr.length != index+1"></span>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props: ["step_arr"],
};
</script>
<style lang="scss" scoped>
.steps_box {
  margin-bottom: 70px;
  .page_info {
    padding: 60px 0;
    padding-top: 56px;
    width: 100%;
    background: rgb(223, 223, 223);
    .box_list {
      vertical-align: middle;
      display: -webkit-box;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      height: 100%;
      align-items: center;
    }
    .box_li {
      text-align: center;
      margin: auto;
      .num {
        .iconfont {
          font-size: 0.3rem;
          vertical-align: middle;
        }

        .text {
          font-weight: bold;
          font-size: 0.18rem;
          line-height: 0.5rem;
          vertical-align: middle;
        }
      }

      .icon {
        .iconfont {
          font-size: 82px;
        }
      }

      .hint {
        width: 1.35rem;
        margin: auto;
        font-size: 0.14rem;
      }
    }

    .icon_right {
      font-size: 48px;
      vertical-align: middle;
      text-align: center;
    }
    .untreated * {
      color: rgb(190, 190, 190) !important;
    }
  }
}
</style>
