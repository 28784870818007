<template>
  <div class="company_box">
    <div v-if="enterprise.status !== 1">
      <div class="company_details">
        <div class="company_status">
          <el-alert
            v-if="enterprise.status === 4"
            show-icon
            :title="detalis.status"
            type="success"
            :closable="false"
          />
          <el-alert
            v-if="[1, 2, 5].includes(enterprise.status)"
            show-icon
            :title="detalis.status"
            type="info"
            :closable="false"
          />
          <el-alert
            v-if="[3, 6].includes(enterprise.status)"
            show-icon
            :title="detalis.status"
            type="primary"
            :closable="false"
            >原因：{{ enterprise.basic_remark || "未说明" }}</el-alert
          >
        </div>
        <div class="company_info">
          <div class="company_title clr">
            <h4 class="fl">基本信息</h4>
            <router-link
              to="/apply_firm?id=1"
              v-if="enterprise.status !== 2 && enterprise.status !== 5"
            >
              <el-button
                @click="MtaH5.clickStat('company', { reset: 'true' })"
                class="fr"
                type="text"
                >企业信息变更，重新认证</el-button
              >
            </router-link>
          </div>

          <div v-if="enterprise.manager && enterprise.productionLicense">
            <el-form label-width="180px">
              <el-form-item label="单位名称：">
                {{ enterprise.companyName }}
                <a
                  @click="viewFile"
                  class="text-blue margin-left"
                  v-if="enterprise.cooperationStatus"
                >
                  <span class="el-icon-download" />
                  下载合作协议
                </a>
              </el-form-item>
              <el-form-item label="社会信用代码：">{{
                enterprise.taxNo
              }}</el-form-item>
              <el-form-item label="企业联系人：">{{
                enterprise.contactName
              }}</el-form-item>
              <el-form-item label="企业联系电话：">{{
                enterprise.contactMobile
              }}</el-form-item>
              <el-form-item label="营业执照：">
                <div class="img">
                  <img
                    v-if="enterprise.businessLicense"
                    :src="enterprise.businessLicense.url"
                    alt="营业执照"
                    v-preview="enterprise.businessLicense.url"
                  />
                </div>
              </el-form-item>
              <el-form-item label="食品经营许可证：">
                <div class="img">
                  <img
                    v-if="enterprise.foodBusinessLicense"
                    :src="enterprise.foodBusinessLicense.url"
                    alt="食品经营许可证"
                    v-preview="enterprise.foodBusinessLicense.url"
                  />
                </div>
              </el-form-item>
              <el-form-item
                label="食品生产许可证："
                v-if="
                  enterprise.productionLicense &&
                  enterprise.productionLicense.productionLicenseCopy
                "
              >
                <span></span>
                <el-row>
                  <el-col :span="8">
                    <div class="img_box">正本/副本</div>
                    <img
                      class="img_box"
                      v-if="
                        enterprise.productionLicense &&
                        enterprise.productionLicense.productionLicenseCopy
                      "
                      :src="
                        enterprise.productionLicense.productionLicenseCopy.url
                      "
                      alt="食品生成许可证副本"
                      v-preview="
                        enterprise.productionLicense.productionLicenseCopy.url
                      "
                    />
                  </el-col>
                  <el-col :span="8">
                    <div class="img_box">品种明细</div>
                    <img
                      v-if="
                        enterprise.productionLicense &&
                        enterprise.productionLicense.productionLicenseDetail
                      "
                      class="img_box"
                      :src="
                        enterprise.productionLicense.productionLicenseDetail.url
                      "
                      alt="食品生成许可证品质明细"
                      v-preview="
                        enterprise.productionLicense.productionLicenseDetail.url
                      "
                    />
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item
                label="食品生产许可证其他图片"
                v-if="
                  enterprise.productionLicense &&
                  enterprise.productionLicense.moreFiles
                "
              >
                <PictureList :list="enterprise.productionLicense.moreFiles" />
              </el-form-item>
            </el-form>
            <div class="company_title clr">
              <h4 class="fl">管理员信息</h4>
              <router-link to="/usermod">
                <el-button
                  class="fr"
                  type="text"
                  @click="MtaH5.clickStat('company', { readmin: 'true' })"
                  >更改管理信息</el-button
                >
              </router-link>
            </div>
            <el-form label-width="180px">
              <el-form-item label="管理员手机号：">{{
                enterprise.manager.mobile
              }}</el-form-item>
              <el-form-item label="管理员微信：">
                <span> {{ enterprise.manager.nickname }} </span>
                <span
                  class="cu-avatar lg round"
                  :style="`background-image: url(${enterprise.manager.headimgurl})`"
                />
              </el-form-item>
              <el-form-item label="注册时间：">{{
                enterprise.manager.createAt
              }}</el-form-item>
            </el-form>

            <div class="company_title">
              <h4>其他信息</h4>
            </div>
            <el-form label-width="180px">
              <el-form-item
                label="企业生产许可证编号："
                v-if="enterprise.productionLicense"
                >{{
                  enterprise.productionLicense.productionLicenseNumber
                }}</el-form-item
              >
              <el-form-item
                label="企业标准号："
                v-for="item in enterprise.enterpriseStandards"
                :key="item.id"
              >
                <a
                  :href="item.standardFile.url"
                  :download="item.standardNumber"
                  target='_blank'
                >
                  <el-button icon="el-icon-download" type="text">{{
                    item.standardNumber
                  }}</el-button>
                </a>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="home_wrap" v-else>
      <div class="home_notice">
        <div class="title">温馨提示</div>
        <div v-if="enterprise.status === 1">
          <div class="info" style="margin-bottom: 20px">
            <div class="info">暂无贵单位的企业信息</div>
            <div class="info">
              为了保证贵单位的合法权利以及标签信息的有效性，完成贵单位企业信息的认证有助于标签审核
            </div>
          </div>
          <router-link to="/apply_firm">
            <el-button
              type="primary"
              @click="MtaH5.clickStat('company', { add: 'true' })"
              >去认证企业</el-button
            >
          </router-link>
        </div>
        <div v-else-if="[2, 5].includes(enterprise.status)">
          <div class="info">
            现在，您的企业可以通过此系统直接上传食品标签的信息，我们将针对该食品标签进行专业、全面的审核，为您企业的安全发展保驾护航。
          </div>
          <div class="info">
            为了更好的保护每家企业的食品标签信息安全，目前食品标签审核服务仅针对通过企业认证的用户开放。
          </div>
          <div class="info" style="padding-top: 50px">
            我们正在核实您的企业信息，请耐心等候！
          </div>
        </div>
        <div v-else-if="[6, 3].includes(enterprise.status)">
          <div class="info">您的认证信息被驳回，请您重新提交企业信息！</div>
          <router-link to="/apply_firm">
            <el-button
              type="primary"
              @click="MtaH5.clickStat('company', { reset: 'true' })"
              >重新认证</el-button
            >
          </router-link>
        </div>
        <div v-else>获取企业信息失败： 状态值为{{ enterprise.status }}</div>
      </div>
      <div class="min_title">认证流程</div>
      <div class="steps_arr">
        <steps :step_arr="step_arr"></steps>
      </div>
    </div>
  </div>
</template>

<script>
import steps from "@/components/home/steps";
import { enterprise_state, moment_set } from "@/assets/js/validate";
import PictureList from "@/components/PictureList";
export default {
  components: {
    steps,
    PictureList,
  },

  data() {
    return {
      step_arr: [
        {
          title: "同意《服务协议》",
          icon: "icontongyixieyi",
          info: "请您认真阅读《士海食标服务协议》条款",
          active: false,
        },
        {
          title: "上传企业资料",
          icon: "iconshangchuanziliao",
          info: "填写用于认证的企业信息并提交",
          active: false,
        },
        {
          title: "等待审核并完成认证",
          icon: "icondengdaishenhe",
          info: "企业成功认证后您就可以享受我们的服务",
          active: false,
        },
      ],
      detalis: {
        status: "",
      },
    };
  },
  computed: {
    enterprise() {
      return this.$store.getters.enterprise;
    },
  },
  created() {
    let that = this;
    let data = this.enterprise;
    that.detalis.status = `${enterprise_state(data.status)}：  （${
      moment_set(data.updateAt) || ""
    }）`;
  },
  methods: {
    viewFile() {
      window.open("/api/company/cooperation/contract", '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.company_box {
  .home_wrap {
    width: 100%;
    margin: auto;
    margin-top: 80px;
    .home_notice {
      background: rgb(51, 51, 51);
      padding: 30px 56px;
      .title {
        font-size: 16px;
        color: #ffffff;
        font-weight: bold;
        line-height: 50px;
      }
      .info {
        font-size: 14px;
        color: rgb(254, 254, 254);
      }
      .homt_btn {
        margin: 17px 0;
        button {
          font-weight: bold;
        }
      }
      .add_money {
        margin-top: 30px;
      }
    }
    .min_title {
      margin-top: 44px;
      font-weight: bold;
      font-size: 26px;
      color: rgb(33, 33, 33);
      line-height: 116px;
      border-top: 2px solid rgb(227, 225, 225);
    }
  }
  .company_details {
    .company_title {
      margin: 15px 0;
      line-height: 40px;
      padding: 0 20px;
      background-color: #f2c811;
    }
    .company_info {
      padding-bottom: 200px;
      .img_box {
        width: 80%;
        text-align: center;
      }
      .img {
        width: 15%;
        img {
          width: 100%;
        }
      }
      .wx_img {
        .img {
          img {
            width: 43px;
            height: 43px;
            border-radius: 50%;
            vertical-align: middle;
          }
        }
      }
    }
    .el-form-item {
      margin-bottom: 0;
    }
  }
}
</style>
