<template>
  <div class="picture-wrap">
    <div
      class="picture-box solid"
      v-for="item in list"
      :key="item.url"
      :style="`width:${width}px;height:${width}px;`"
    >
      <div class="picture">
        <img v-preview="item.url" :src="item.url" :alt="item.originName" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: [],
    },
    width: {
      type: Number,
      default: 150,
    },
  },
};
</script>
<style lang="scss" scoped>
.picture-wrap {
  .picture-box {
    display: inline-block;
    margin-right: 15px;
  }
  .picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 10px;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 100%;
      display: inline-block;
      width: auto;
      height: auto;
    }
  }
}
</style>